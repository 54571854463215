window.signPledge = =>
  tweetUrl = document.getElementById('tweet-url-input').value

  response = await fetch '/sign-pledge',
    method: 'POST'
    mode: 'cors'
    cache: 'no-cache'
    credentials: 'same-origin'
    headers:
      'Content-Type': 'application/json'
    redirect: 'follow'
    referrerPolicy: 'no-referrer'
    body: JSON.stringify {tweetUrl}

  response = await response.json()

  console.log response

  {success, message} = response

  messageEl = document.getElementById('submit-tweet-link-message')
  messageEl.innerText = message

  if success
    messageEl.classList.remove 'error'
    messageEl.classList.add 'success'

    {username, tweetUrl} = response

    a = document.createElement 'a'
    linkText = document.createTextNode "@" + username
    a.appendChild linkText
    a.href = tweetUrl
    a.target='_NEW'
    document.getElementById('signatories').append a

  else
    messageEl.classList.remove 'success'
    messageEl.classList.add 'error'



